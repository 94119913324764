@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Press Start 2P";
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #11011e;
  /* background-image: url("./images/hero-background.png");
  background-size: cover;
  background-position: center; */
}

/* Hamburger Menu Styles */

.hamburger-menu {
  z-index: 1002;
}

.hamburger-button {
  background: none;
  border: none;
  cursor: pointer;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.hamburger-button div {
  width: 25px;
  height: 3px;
  background-color: #5d5fef;
  transition: all 0.3s ease;
}

/* Mobile Menu Styles */
.mobile-menu {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100vh;

  background-color: #11011e;
  padding: 100px 20px 20px; /* Increased top padding to account for navbar */
  flex-direction: column;
  gap: 20px;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000; /* Ensure it's above other content */
  box-sizing: border-box;
  border-left: 1px solid #8f00ff;
}

.mobile-menu.open {
  transform: translateX(0);
  opacity: 1;
}

/* Add overlay when menu is open */
.mobile-menu::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.mobile-menu.open::before {
  opacity: 1;
  pointer-events: auto;
}

.mobile-social-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  gap: 20px;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #8f00ff;
}

.mobile-social-links a {
  display: inline-block; /* Make links block-level */
  padding: 10px 0; /* Add padding for better touch targets */
  width: fit-content; /* Ensure links only take up necessary width */
}

/* Animation for hamburger menu when open */
.hamburger-button.open div:nth-child(1) {
  transform: rotate(45deg) translate(8px, 8px);
}

.hamburger-button.open div:nth-child(2) {
  opacity: 0;
}

.hamburger-button.open div:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

.text-button {
  background: none;
  border: none;
  color: #ff00d6;
  cursor: pointer;
  font-family: "Press Start 2P";
  font-size: 14px;
  padding: 5px 10px;
}

.text-button:hover {
  background-color: #ff00d6;
  color: black;
  transition: all 0.3s ease;
}
