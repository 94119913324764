@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 0;
}

@layer utilities {
  @keyframes flip {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

  .animate-flip {
    animation: flip 2s infinite linear;
  }

  .delay-100 {
    animation-delay: 0.5s;
  }

  .delay-200 {
    animation-delay: 1s;
  }

  .delay-300 {
    animation-delay: 1.5s;
  }

  .delay-400 {
    animation-delay: 2s;
  }
}
